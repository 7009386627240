import React from 'react';
import { withRouter } from "react-router";
import '../App.css';
import axios from 'axios';
import { Form, Descriptions, Typography, Input, Button, Col } from 'antd';
import {baseURL} from '../API/url'
const { Title } = Typography;

class Profile extends React.Component<any,any> {
	constructor(props:any) {
	super(props);
	this.state = { data: {} };
	}

	componentDidMount = async () => {
		if(!this.props.isAuthenticated) {
			window.location.assign("/login")
			return;
		}
		let callurl = baseURL+'/partnerProfile';
		await axios.get(callurl)
		.then((res)=>{
			if(res.data){
				this.setState({data:res.data.partner})
			}
			else{
				console.log('user not found');
			}
		}, (err)=>{
			console.log('[err] ' + err);
		})	

	}

	handleChange = async (e: any) => {
		let user = this.state.data;
		user.password = e.user.password;
		this.setState({ data: user });
		console.log(this.state.data)
		try {
			await axios.put(baseURL+"/user", this.state.data)	
			alert('New Password Successfully Updated. Please Login With Your New Password' )
		} catch (err) {
			alert(err)
		}
	}
	 
	render(){
		return (
 			<div id="user">
				<Descriptions title="User Profile" layout="horizontal">
					<Descriptions.Item label="Email">{this.state.data.emailAddress || ''}</Descriptions.Item>
					<Descriptions.Item label="First Name">{this.state.data.firstName || ''}</Descriptions.Item>
					<Descriptions.Item label="Last Name">{this.state.data.lastName || ''}</Descriptions.Item>
					<Descriptions.Item label="Phone">{this.state.data.phoneHome || ''}</Descriptions.Item>
				</Descriptions>
					<Descriptions title="Mailing Address" layout="horizontal">
					<Descriptions.Item label="Street">{this.state.data.street || ''}</Descriptions.Item>  
					<Descriptions.Item label="City">{this.state.data.city || ''}</Descriptions.Item>
					<Descriptions.Item label="Province">{this.state.data.province || ''}</Descriptions.Item>
					<Descriptions.Item label="Post Code">{this.state.data.postCode || ''}</Descriptions.Item>
				</Descriptions>
				<Button className="ant-btn"
									onClick={
										()=>{							
											this.props.history.push('/changeProfile')			 					
										//	window.location.href = '/changeProfile';
											}
									}
								>
									Change Profile
				</Button>	
			   
	<Col span={7}>
	<Title level={4} style={{ marginTop: '50px' }}>Change Password</Title>
	<Form  name="nest-messages" onFinish={this.handleChange}
			layout="vertical">
			<Form.Item
			name={['user', 'password']}
			label="New Password"
			rules={[
			{
			required: false,
			},
			{
			pattern:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d`~!@#$%^&*()_+-=]{8,}$/,
			message: `Password should be minimum eight characters, at least one uppercase letter, one lowercase letter and one number`
			}
			]}
			hasFeedback
			>
			<Input.Password />

		</Form.Item>

			<Form.Item
			name="confirm"
			label="Confirm Password"
			dependencies={['user','password']}
			hasFeedback
			rules={[
			{
			required: false,
			},
			({ getFieldValue }) => ({
			validator(_, value) {
				if (!value || getFieldValue(['user','password']) === value) {
				return Promise.resolve();
				}
				return Promise.reject(new Error('The two passwords that you entered do not match!'));
			},
			}),
			]}
			>
			<Input.Password />
			</Form.Item>
			<Button type="primary" htmlType="submit" className="login-form-button">
					Submit								   
			</Button>
			</Form>
			</Col>
			</div>
		)
	}
}

export default withRouter(Profile);