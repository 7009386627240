import React from 'react';
import { withRouter } from "react-router";
import '../App.css';
import axios from 'axios';
import { Typography, Button } from 'antd';
import { baseURL } from '../API/url'
import { PDFDocument } from 'pdf-lib';
import download from 'downloadjs';
import { CrataxRO } from '../../../service/src/cratax/cratax.dto';

const { Title } = Typography;

class Taxform extends React.Component<any,any> {
	constructor(props:any) {
	super(props);
	this.state = { 
		TaxData: [],
	 };
	}

	componentDidMount = async () => {
		if(!this.props.isAuthenticated) {
			window.location.assign("/login")
			return;
		}

		try {
			const response = await axios.get(baseURL+"/partnerRec");
			const partners = response.data;
			const taxDataArray: any[] = [];
			for (let i=0; i < partners.length; i++) {
				const partner = partners[i];
				const response = await axios.get(baseURL+"/getTaxRecord?account="+partner.account);
				for (let j=0; j < response.data.length; j++) {
					taxDataArray.push(response.data[j]);
					this.setState({ TaxData: taxDataArray })
				}
			}
			this.setState({ TaxData: taxDataArray }, this.sortTaxDataByYear);
		} catch (error) {
			alert('No Tax Record Was Found');
			this.props.history.push('/portfolio');
			await this.render();
			return;
		}
	}

	sortTaxDataByYear = () => {
		const sortedTaxData = [...this.state.TaxData].sort((a, b) => {
		  return a.taxYear - b.taxYear;
		});
		this.setState({ TaxData: sortedTaxData });
	  };

	downloadT5 = async (data: CrataxRO) => {
		try {
			const url = 'https://ginkgowebsite.s3.us-east-2.amazonaws.com/t5-2024b.pdf';
			const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
			const sinCode = (data.sin === '000000000') ? data.businessNumber : data.sin;
			const res = await axios.get(baseURL+"/decodeSIN?sin=" + sinCode);
			// Load a PDFDocument from the existing PDF bytes
			const pdfDoc = await PDFDocument.load(existingPdfBytes, { ignoreEncryption: true });
			// Get form
			const form = pdfDoc.getForm();
			// Fill form fields
	 		form.getTextField('SIN').setText(res.data.toString())
			form.getTextField('interest_amount').setText(data.intAmount.toString().replace('$', ''));
			form.getTextField('year').setText(data.taxYear);	
			form.getTextField('payer_name').setText('Ginkgo Investment Mortgage Corporation');
			form.getTextField('payer_street').setText('243 Consumers Road, Suite 210');
			form.getTextField('payer_addr2').setText('Toronto, ON M2J 4W8');
			form.getTextField('payer_phone').setText('416-901-5133');
			switch (data.accountType) {
				case '3':
				  form.getTextField('rcpt_name').setText(data.businessName);
				  break;
				case '2':
				  form.getTextField('rcpt_name').setText(data.fullName);
				  break;
				case '1':
				  form.getTextField('rcpt_name').setText(data.firstName + ' ' + data.lastName);
				  break;
			  }
			form.getTextField('rcpt_street').setText(data.street);
			form.getTextField('rcpt_addr2').setText(data.city + ' ' + data.province + ' ' + data.postCode);
			form.getTextField('rpt_code').setText(data.submmitCode);
			form.getTextField('type').setText(data.accountType);
			form.getTextField('account').setText(data.accountNum);
			form.flatten();
			// Serialize the PDFDocument to bytes (a Uint8Array)
			const pdfBytes = await pdfDoc.save();
			// Trigger the browser to download the PDF document
			download(pdfBytes, 'T5-' + data.accountNum + '-' + data.product + '-' + data.taxYear + '.pdf', 'application/pdf');
			} catch (error) {
			console.error('Error modifying PDF:', error);
			}	  
	  };
	 
	render(){
		return (
 			<div id="taxform">
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '50px', marginBottom: '15px' }}>
				<Title level={4}>Tax Slip Information</Title>
				</div>
	            <div className="ant-table " style={{ display: 'flex', flex: 'auto', }}>
	                  <table>
	                    <thead className="ant-table-thead">
	                      <tr>
							<th>Account</th>
							<th>Product</th>
	                        <th>Interest Received</th>
							<th>Tax Year</th>
	                        <th>Download</th> 
	                      </tr>
	                    </thead>  
	                  <tbody className="ant-table-tbody">
	                      {

								this.state.TaxData.map((value:any, i:number)=>(

								  <tr key={i}>
									<td>{value.accountNum}</td>
									<td>{value.product}</td>
									<td>{value.intAmount}</td>
									<td>{value.taxYear}</td>
									<td>
										<Button 
										onClick={() => this.downloadT5({
											accountNum: value.accountNum,
											intAmount: value.intAmount,
											product: value.product,
											sin: value.sin,
											taxYear: value.taxYear,
											firstName: value.firstName,
											lastName: value.lastName,
											initate: value.initate,
											fullName: value.fullName,
											street: value.street,
											city: value.city,
											province: value.province,
											postCode: value.postCode,
											businessNumber: value.businessNumber,
											businessName: value.businessName,
											accountType: value.accountType,
											submmitCode: value.submmitCode
										})}
										>
										T5
										</Button>
									</td>
									</tr> 

								))
							}
 	                            <tr>
									 <td></td>
									<td></td>
									<td></td>
								</tr>           	                    
	                    </tbody> 
	                  </table>
	            </div> 
			</div>
		)
	}
}

export default withRouter(Taxform);
