import React from 'react';
import axios from 'axios';
import { baseURL } from '../API/url'
import '../App.css';
import { withRouter } from "react-router";
import { Form, Input, Button, Row, Col} from 'antd';
import { UserOutlined } from '@ant-design/icons';

class ResetPassword extends React.Component<any,any> {
	constructor(props:any) {
	    super(props);
		
	}

    state = {
        data:{email:''}
    }

	componentDidMount = () => {
		
	}

	onFinish = (values: any) => {
		this.setState({
			data:{email:values.email}
		  });

		axios.post(baseURL+"/resetPassword", this.state.data, { withCredentials: false })		
		.then((res) => {

			if(res.data.error) {
				alert(res.data.error);
				return;
			}

			if(res.data) {
				alert(res.data.message);

				this.props.history.push('/login');
			}
			
		}, (err)=>{
			console.log('[err] ' + err);
		})
	};

	render(){
		return (
				<Row justify="center" style={{ marginTop: '50px' }}>
      			<Col span={8}>
				<Form
				layout='vertical'
				name="normal_login"
				className="login-form"
				initialValues={{email: this.state.data.email }}
				onFinish={this.onFinish}
			>
				<Form.Item
				label="Enter your email:"
				name="email"
				rules={[{ required: true, type: 'email', message: 'Please input your Email!' }]}
				>
				<Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
				</Form.Item>
				<Form.Item>
				<Button type="primary" htmlType="submit" className="login-form-button">
					Reset Password								   
				</Button>
				</Form.Item>
				</Form>
				</Col>
    			</Row>
		)
	}
}
export default withRouter(ResetPassword);




